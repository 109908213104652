
.left-tool {
    position: absolute;
    z-index: 999;
    width: 80px;
    /*height: calc(100% - 150px);*/
    height: calc(100% - 92px);
    background-color: #333;
    right: 13px;
    top: 92px;
    padding-top: 80px;
    box-sizing: border-box;
    .tool-item {
        color: #fff;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        i.iconfont {
            margin-bottom: 10px;
        }
        &:nth-child(2) i.iconfont {
            font-size: 14px;
        }
        &:nth-child(3) i.iconfont {
            font-size: 18px;
        }
    }
}
.add-tool-dialog {
    ::v-deep .el-dialog {
        border-radius: 6px;
    }
    ::v-deep .el-dialog__header {
        padding: 15px 20px;
        color: #fff;
        font-size: 16px;
        background-color: #409EFF;
        border-radius: 6px 6px 0 0;
        .dialog-header {
            display: flex;
            position: relative;
            .center {
                position: absolute;
                display: inline-block;
                text-align: center;
                width: 100%;
            }
        }
        ::v-deep .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }
    }
    ::v-deep .el-dialog__body {
        /*height: 467px;*/
        padding: 0;
        .dialog-content {
            height: 100%;
            background-color: #F2F2F2;
            .content-item {
                display: flex;
                flex-direction: column;
            }
            .resources-item {
                /*height: 467px;*/
                height: 667px;
            }
            .recording-item {
                background-color: #fff;
                height: 267px;
            }
            .view-recording-item {
                height: 447px;
                .content {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 13px;
                    background-color: #fff;
                    .recording-name {
                        height: 60px;
                    }
                }
            }
            .view-link-item {
                background-color: #fff;
                height: 320px;
                .content {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #F2F2F2;
                    margin: 20px;
                }
            }
            .type-list {
                display: flex;
                align-items: center;
                height: 80px;
                background-color: #fff;
                padding: 0 20px;
            }
            .content {
                display: flex;
                flex: 1;
                height: 1%;
                padding: 13px;
                .left-list {
                    width: 200px;
                    height: 100%;
                    background-color: #fff;
                    ::v-deep .el-scrollbar__wrap {
                        overflow-x: hidden;
                        .el-scrollbar__view {
                            height: 100%;
                        }
                    }
                    .type-item {
                        margin: 10px;
                        height: 29px;
                        line-height: 29px;
                        padding-left: 10px;
                        color: #333;
                        font-size: 14px;
                        cursor: pointer;
                        width: calc(100% - 30px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        &:hover {
                            background-color: #ECF5FF;
                            color: #409EFF;
                        }
                    }
                    .current {
                        background-color: #ECF5FF;
                        color: #409EFF;
                    }
                }
                .right-list {
                    flex: 1;
                    width: 1%;
                    margin: 22px 22px 22px 35px;
                    box-sizing: border-box;
                    /*background-color: rebeccapurple;*/
                }
            }
        }
    }
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
