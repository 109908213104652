
.chapter-wrapper {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .chapter-content {
        color: #999;
        margin: 20px 0 10px;
        .line-item {
            &.lin-item-one {
                margin-bottom: 10px;
            }
            .line-name {
                padding: 0 20px;
                line-height: 36px;
                &.line-name-one {
                    color: #fff;
                }
                &.line-name-two {
                    padding-left: 40px;
                    color: #ccc;
                    &.current {
                       color: #ff0000;
                    }
                }
                &.line-name-three {
                    display: flex;
                    cursor: pointer;
                    padding-left: 60px;
                    .name-text {
                        margin-left: 8px;
                    }
                    .name-text {
                        width: 1%;
                        flex: 1;
                    }
                    .teaching-status {
                        width: 80px;
                        display: flex;
                        justify-content: flex-end;
                        .iconfont {
                            margin-left: 10px;
                        }
                        &.classed {
                            color: #3296FA;
                        }
                        &.classing {
                            color: #ff0000;
                        }
                    }
                }
                &.line-name-three-two {
                    padding-left: 40px;
                }
            }
        }
    }
}
